import React, { FC, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Wrapper, Status } from '@googlemaps/react-wrapper'

import SEO from '../components/seo'
import Navigation from '../components/navigation'

const SatuMarePosition: google.maps.LatLngLiteral = {
	lat: 47.7928237,
	lng: 22.8746752,
}

const DebrecenAirportPosition: google.maps.LatLngLiteral = {
	lat: 47.4911,
	lng: 21.6078,
}

const HeroSection = styled.div`
	position: relative;
	width: 100%;
	background-color: ${p => p.theme.colors.yves};
	color: ${p => p.theme.colors.silver};
`

const Content = styled.div`
	max-width: 1024px;
	height: 100%;
	margin: 0 auto;
	padding-left: 2rem;
	padding-right: 2rem;

	h1 {
		margin-bottom: 1rem;
		font-weight: normal;
	}

	section {
		padding: 2rem 0;
	}

	h2 {
		padding-top: 2rem;
		margin-bottom: 1rem;
	}
`

const render = (status: Status) => {
	return <h1>{status}</h1>
}

const Map: React.FC<{ options?: google.maps.MapOptions }> = ({
	options,
	children,
}) => {
	const ref = React.useRef<HTMLDivElement>(null)
	const [map, setMap] = React.useState<google.maps.Map>()

	React.useEffect(() => {
		if (ref.current && !map) {
			setMap(new window.google.maps.Map(ref.current, options))
		}
	}, [ref, map])

	return (
		<>
			<div ref={ref} style={{ height: 400 }} />
			{React.Children.map(children, child => {
				if (React.isValidElement(child)) {
					// set the map prop on the child component
					return React.cloneElement(child, { map })
				}
			})}
		</>
	)
}

const Marker: React.FC<google.maps.MarkerOptions> = options => {
	const [marker, setMarker] = React.useState<google.maps.Marker>()

	React.useEffect(() => {
		if (!marker) {
			setMarker(new google.maps.Marker())
		}

		// remove marker from map on unmount
		return () => {
			if (marker) {
				marker.setMap(null)
			}
		}
	}, [marker])

	React.useEffect(() => {
		if (marker) {
			marker.setOptions(options)
		}
	}, [marker, options])

	return null
}

const TransportPage: FC<{}> = () => (
	<>
		<SEO title="How to get there" />
		<HeroSection>
			<Content>
				<Navigation />
				<h1>How to get to Satu Mare</h1>
				<p>
					The best way to get to Satu Mare from London, Paris or Brussles is to
					fly to Debrecen Airport, in nearby Hungary. If you're flying from
					other destinations, Budapest, Cluj or Bucharest are further options.
					Bucharest has direct flights to Satu Mare if you chose to fly via
					Bucharest.
				</p>
				<Wrapper apiKey={process.env.GATSBY_GOOGLE_API_KEY} render={render}>
					<Map
						options={{
							zoom: 9,
							center: {
								lat: (SatuMarePosition.lat + DebrecenAirportPosition.lat) / 2,
								lng: (SatuMarePosition.lng + DebrecenAirportPosition.lng) / 2,
							},
						}}
					>
						<Marker position={SatuMarePosition} />
						<Marker position={DebrecenAirportPosition} />
					</Map>
				</Wrapper>
				<h2>Debrecen (Hungary) International Airport</h2>
				<p>
					Flights to Debrecen run on Wednesday, Thursday and Friday, with the
					return flight on Sunday. The flight to Debrecen is quite late, departs
					9:30 PM from London and lands at 2:15 AM Romanian time. On the plus
					side you don't need to miss an extra day of work.
				</p>
				<p>
					We expect most of you to take this flight on Wednesday or Thursday
					evening and for those of you we will arange for a minibus to transfer
					you to Satu Mare. The shuttle journey is 112km long and lasts 1h
					40min. Be aware that Debrecen is in Hungary therefore the journey will
					involve a border crossing in case this impacts your visa/documenation
					in any way.
				</p>
				<br />
			</Content>
		</HeroSection>
	</>
)

export default TransportPage
